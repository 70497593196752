import styled from "styled-components";
import { Color, colors, colorsRGB, fontSizes } from "./constants";
import {
  AlignItems,
  AlignSelf,
  Dimensions,
  FlexDirection,
  FontFam,
  JustifyContent,
  Overflow,
  PDisplayType,
  FlexWrap,
  Cursor,
  FontWeight,
  WhiteSpace,
} from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${(props) => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  fontFamily?: FontFam;
  noMargin?: boolean;
  margin?: string;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeight;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: PDisplayType;
  whiteSpace?: WhiteSpace;
  cursor?: Cursor;
  padding?: number;
  textAlign?: string;
  letterSpacing?: string;
}>`
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ display }) => display && `display: ${display};`}
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.2}px;
  }

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: 80%;
      text-align: center;
      margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  color: ${(props) => (props.color ? colors[props.color] : colors.darkBlue)};
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}

  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${colors[hoverColor]};
    }`}
    ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const H1 = styled.h1<{
  fontFamily?: FontFam;
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
}>`
  color: ${(props) => (props.color ? colors[props.color] : colors.darkBlue)};
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  font-size: ${(props) => props.fontSize ?? fontSizes.h1.default}px;
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
    line-height: ${fontSizes.h1.mobile * 1.2}px;
  }
`;

export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeight;
  fontFam?: FontFam;
}>`
  ${({ fontFam }) => fontFam && `font-family: ${fontFam};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  font-size: ${fontSizes.h2.default}px;
  white-space: initial;
  margin: ${(props) => props.margin ?? "20px 0"};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
    line-height: ${fontSizes.h2.mobile * 1.2}px;
  }
`;

export const H3 = styled.h3<{ color?: Color; margin?: string }>`
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: ${fontSizes.h3.default}px;
  ${({ color }) => color && `color: ${colors[color]};`}
  margin: ${(props) => props.margin ?? "20px 0"};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile * 1.2}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid
    ${(props) => (props.color ? colors[props.color] : colors.darkBlue)};
  height: ${(props) =>
    props.dimensions?.height ? props.dimensions?.height : `auto`};
  width: ${(props) =>
    props.dimensions?.width ? props.dimensions?.width : `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 1}px solid
    ${(props) => (props.color ? colors[props.color] : colors.darkBlue)};
  ${({ dimensions }) =>
    dimensions && dimensions.width && `width: ${dimensions.width ?? "100%"};`}
  height: ${(props) => props.dimensions?.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: FlexDirection;
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  mobileAlignSelf?: AlignSelf;
  borderRadius?: number;
  overflow?: Overflow;
  padding?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  mobileJustifyContent?: JustifyContent;
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  cursor?: Cursor;
  reverseDirection?: boolean | null;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
}>`
  display: flex;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ reverseDirection }) =>
    reverseDirection && `flex-direction: row-reverse;`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}

    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.height &&
      `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.width &&
      `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.height &&
      `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.width &&
      `width: ${mobileDimensions.width};`}

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) =>
      mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  columnGap?: string;
  rowGap?: string;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap};`}

  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 2}, 1fr);
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${(props) => props.lineHeight ?? 18}px;
  cursor: pointer;
  margin: 0;
  user-select: none;
  font-size: 20px;
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  color: ${(props) => (props.color ? colors[props.color] : colors.darkBlue)};
  &:hover {
    color: ${(props) =>
      props.hoverColor ? colors[props.hoverColor] : colors.darkBlue};
  }
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;
