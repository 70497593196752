export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;
export const STANDARD_SERVING_SIZE = 2;
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;

export const DEFAULT_OFFSET = 100;
export const DEFAULT_FONT_SIZE = 14;

export const colors = {
  white: "#fff",
  black: "#000000",
  darkBlue: "#162742",
  lightBlue: "#E4F4FF",
  cyan: "#59B4C8",
  transparent: "transparent",
};

export const colorsRGB = {
  darkBlue: (opacity?: number) => `rgba(22,	39,	66, ${opacity ?? "0.5"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
};

export type Color = keyof typeof colors;

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact",
  notFound: "/404",
  media: "/media",
};

export const assets = {
  flag: require("@assets/flag.svg"),
  hamburger: require("@assets/hamburger.svg"),
  close: require("@assets/close.svg"),
  mapPin: require("@assets/pin.svg"),
  telecom: require("@assets/telecom.svg"),
};

export const fontSizes = {
  p: { default: 14, mobile: 12 },
  h1: { default: 40, mobile: 27 },
  h2: { default: 35, mobile: 23 },
  h3: { default: 20, mobile: 16 },
};

export const buttonStyle = {
  base: {
    bg: `${colors.darkBlue}`,
    text: `${colors.white}`,
    border: `${colors.darkBlue}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.darkBlue}`,
    hoverText: `${colors.darkBlue}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.cyan}`,
    hoverBorder: `${colors.cyan}`,
    hoverText: `${colors.white}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.darkBlue}`,
    hoverText: `${colors.darkBlue}`,
  },
};
