import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";

import { colors, TABLET_BREAKPOINT, pages } from "@util/constants";
import { Container, P, VerticalSeparator, A } from "@util/standard";
import { Query } from "@graphql-types";
import { Link } from "@sub";
import { CRA } from "./header";

const Wrapper = styled.footer`
  background-color: ${colors.darkBlue};
  padding: 90px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 70px 0;
  }
`;

const LinksContainer = styled(Container)<{ noPadding?: boolean }>`
  flex-direction: column;
  padding: 0 115px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: ${(props) => (props.noPadding ? "0" : "0 60px 0 0")};
  }
`;

const InnerContainer = styled(Container)`
  width: 80%;
  margin: auto;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const DesktopContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileContainer = styled(Container)`
  flex-direction: column;
  margin-top: 45px;
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const Footer = () => {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        quickLinks {
          url
          newwindow
          linktext
          internallink
          _key
        }
        resources {
          url
          newwindow
          linktext
          internallink
          _key
        }
        phone
        email
      }
    }
  `);

  if (sanityFooter == null) {
    return null;
  }

  const Separator = ({ width }: { width?: string }) => (
    <VerticalSeparator color="white" dimensions={{ height: "auto", width }} />
  );

  const Desktop = () => (
    <DesktopContainer>
      <LinksContainer>
        <P fontWeight="bold" color="white">
          Quick Links
        </P>
        {sanityFooter.quickLinks?.map((menuItem) => {
          return (
            <Link
              {...menuItem}
              key={menuItem?._key}
              color="white"
              padding="0 0 18px 0"
            />
          );
        })}
      </LinksContainer>
      <Separator />
      <LinksContainer>
        <P fontWeight="bold" color="white">
          Resources
        </P>
        {sanityFooter.resources?.map((menuItem) => {
          return (
            <Link
              {...menuItem}
              key={menuItem?._key}
              color="white"
              padding="0 0 18px 0"
            />
          );
        })}
      </LinksContainer>
      <Separator />
      <LinksContainer>
        <P fontWeight="bold" color="white">
          Contact
        </P>
        <A
          color="white"
          href={`tel:${sanityFooter.phone?.replace(/[()]/g, "")}`}
        >
          {sanityFooter.phone}
        </A>
        <A
          margin="20px 0 0 0"
          color="white"
          href={`mailto:${sanityFooter.email}`}
        >
          {sanityFooter.email}
        </A>
      </LinksContainer>
    </DesktopContainer>
  );

  const Mobile = () => (
    <MobileContainer>
      <Container margin="0 0 45px 0">
        <LinksContainer>
          <P fontWeight="bold" color="white">
            Quick Links
          </P>
          {sanityFooter.quickLinks?.map((menuItem) => {
            return (
              <Link
                {...menuItem}
                key={menuItem?._key}
                color="white"
                padding="0 0 18px 0"
              />
            );
          })}
        </LinksContainer>
        <Separator width="60px" />
        <LinksContainer noPadding>
          <P fontWeight="bold" color="white">
            Resources
          </P>
          {sanityFooter.resources?.map((menuItem) => {
            return (
              <Link
                {...menuItem}
                key={menuItem?._key}
                color="white"
                padding="0 0 18px 0"
              />
            );
          })}
        </LinksContainer>
      </Container>
      <LinksContainer>
        <P fontWeight="bold" color="white">
          Contact
        </P>
        <P color="white">{sanityFooter.phone}</P>
        <P color="white">{sanityFooter.email}</P>
      </LinksContainer>
    </MobileContainer>
  );

  return (
    <Wrapper>
      <InnerContainer>
        <CRA color="white" onClick={() => navigate(pages.home)}>
          CRA
        </CRA>
        <Desktop />
        <Mobile />
      </InnerContainer>
    </Wrapper>
  );
};

export default Footer;
