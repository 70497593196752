import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";
import moment from "moment-timezone";

import { Container, P, VerticalSeparator } from "@util/standard";
import {
  assets,
  Color,
  colors,
  pages,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Query } from "@graphql-types";
import { Link, MenuDrawer } from "@sub";

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  background-color: ${colors.white};
  z-index: 10;
`;

const HeaderContainer = styled(Container)`
  height: 60px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0 32px;
  }
`;

const TimeContainer = styled(Container)`
  height: 40px;
  width: auto;
  align-items: center;
  justify-content: center;
  background-color: ${colors.cyan};
`;

const MobileLinkContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 70%;
  height: 100%;
  margin: auto;
  justify-content: center;
`;

const DesktopLinksContainer = styled(Container)`
  margin: 0;
  width: auto;
  justify-content: space-between;

  .link {
    padding-left: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const LogoImg = styled.img`
  margin-left: 25px;
  width: 41px;
  height: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const Img = styled.img`
  user-select: none;
  cursor: pointer;
`;

const Close = styled.img`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 25px;
  right: 22px;
  user-select: none;
  cursor: pointer;
`;

const LogoWrapper = styled(Container)`
  user-select: none;
  cursor: pointer;
  align-items: center;
`;

const MenuItem = styled(Container)<{ margin?: string }>`
  margin-bottom: 49px;
`;

export const CRA = styled.p<{ color?: Color }>`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.165em;
  cursor: pointer;
  ${({ color }) => color && `color: ${colors[color]};`}
`;

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isBrowser = () => typeof window !== "undefined";

  const { sanityNavigation }: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        menuItems {
          url
          newwindow
          linktext
          internallink
          _key
        }
      }
    }
  `);

  if (sanityNavigation == null) {
    return null;
  }

  const handleOpenMenu = () => {
    setDrawerVisible(true);
  };

  const handleCloseMenu = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    return () => {
      setDrawerVisible(false);
    };
  }, []);

  const MobileMenu = () => {
    return (
      <MobileLinkContainer>
        {sanityNavigation.menuItems?.map((menuItem) => {
          const url = menuItem?.url === "/" ? "/" : `/${menuItem?.url}`;
          const isCurrent = isBrowser() && url === window.location.pathname;

          return (
            <MenuItem
              key={menuItem?._key}
              onClick={() => setDrawerVisible(false)}
            >
              {isCurrent && <VerticalSeparator dimensions={{ width: "1px" }} />}
              <Link
                {...menuItem}
                padding="0px 20px"
                color="darkBlue"
                isBold={isCurrent}
              />
            </MenuItem>
          );
        })}
      </MobileLinkContainer>
    );
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <LogoWrapper onClick={() => navigate(pages.home)}>
          <CRA>CRA</CRA>
          <LogoImg src={assets.flag} />
        </LogoWrapper>
        <DesktopLinksContainer>
          {sanityNavigation.menuItems?.map((menuItem) => {
            const url = menuItem?.url === "/" ? "/" : `/${menuItem?.url}`;
            const isCurrent = isBrowser() && url === window.location.pathname;

            return (
              <Link
                {...menuItem}
                key={menuItem?._key}
                padding="0px 20px"
                color="darkBlue"
                isUnderlined={isCurrent}
                isBold={isCurrent}
              />
            );
          })}
        </DesktopLinksContainer>
        <MobileWrapper>
          <Img
            alt="hamburger-icon"
            src={assets.hamburger}
            onClick={handleOpenMenu}
          />
          <MenuDrawer
            visible={drawerVisible}
            onClose={handleCloseMenu}
            placement="right"
          >
            <Close
              alt="close-icon"
              src={assets.close}
              onClick={handleCloseMenu}
            />
            <MobileMenu />
          </MenuDrawer>
        </MobileWrapper>
      </HeaderContainer>
      <TimeContainer>
        <P
          fontSize={16}
          color="lightBlue"
        >{`Local Cook Island Time ${moment()
          .tz("Pacific/Honolulu")
          .format("- ddd DD MMM HH:mm")} CKT`}</P>
      </TimeContainer>
    </Wrapper>
  );
};

export default Header;
