import React from "react";

import PortableText from "@sanity/block-content-to-react";
import { Link } from "@sub";
import styled from "styled-components";
import {
  colors,
  fontSizes,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN,
} from "@util/constants";
import { Maybe, Scalars } from "@graphql-types";
import { urlForImageRef } from "@util/sanityClient";
import { Container } from "@util/standard";
import Button from "./button";

const Img = styled(Container)<{ url?: string }>`
  margin: 20px 0px 35px 0px;
  height: 700px;
  width: 100%;
  ${({ url }) => url && `background-image: url(${url})`};
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 600px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 500px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
    margin: 20px 0px;
  }
`;

const StyledPortableText = styled(PortableText)<{ fontSize?: number }>`
  h1 {
    font-size: ${fontSizes.h1.default};
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobile * 1.2}px;
    }
  }

  h3 {
    font-size: ${fontSizes.h3.default};
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h3.mobile}px;
      line-height: ${fontSizes.h3.mobile * 1.2}px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: ${(props) => props.fontSize ?? fontSizes.p.default}px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${(props) => props.fontSize ?? fontSizes.p.default}px;
      line-height: ${fontSizes.p.mobile * 1.2}px;
    }
  }

  a {
    text-decoration-line: underline;
  }
`;
interface Props {
  blocks: Maybe<Scalars["JSON"]>;
  fontSize?: number;
}

const serializer = {
  types: {
    link: (props: any) => {
      return (
        <Link
          {...props.node}
          opacity={0.5}
          className={
            props.node.linkDisplay ? props.node.linkDisplay : "custom-link"
          }
        />
      );
    },
    image: (props: any) => {
      return <Img url={urlForImageRef(props.node.asset).url() as string} />;
    },
    fileUpload: (props: any) => {
      if (!props.node.file) return null;
      const downloadLink = `${props.node.file.asset.url}`;

      return (
        <Container>
          <Button text={props.node.title} linkToNewWindow={downloadLink} />
        </Container>
      );
    },
  },
};

const BlocksContent = ({ blocks, fontSize }: Props) => {
  return (
    <StyledPortableText
      blocks={blocks}
      serializers={serializer}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      fontSize={fontSize}
    />
  );
};

export default BlocksContent;
