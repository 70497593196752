import React from "react";
import styled from "styled-components";

import { Maybe, SanityBlock } from "@graphql-types";
import { P } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, fontSizes, Color } from "@util/constants";

const Span = styled(P)<{
  textStyle?: string | null | undefined;
  fontSize?: number;
  mobileFontSize?: number;
  color?: Color;
}>`
  margin: 0;
  font-family: ${(props) =>
    props.textStyle === "normal" ? "Source Sans Pro" : "Playfair Display"};
  font-weight: ${(props) =>
    props.textStyle === "playfairDisplayBold" ? 700 : 400};
  font-size: ${(props) =>
    props.textStyle === "normal"
      ? 20
      : props.fontSize
      ? props.fontSize
      : fontSizes.h1.default}px;
  line-height: ${(props) =>
    props.fontSize ? props.fontSize * 1.2 : fontSizes.h1.default * 1.2}px;

  ${({ color }) => color && `color: ${colors[color]};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) =>
      props.textStyle === "normal"
        ? 14
        : props.mobileFontSize
        ? props.mobileFontSize
        : fontSizes.h1.mobile}px;
    line-height: ${(props) =>
      props.mobileFontSize
        ? props.mobileFontSize * 1.2
        : fontSizes.h1.mobile * 1.2}px;
  }
`;

interface Props {
  blocks: Maybe<SanityBlock>[] | null | undefined;
  fontSize?: number;
  mobileFontSize?: number;
  color?: Color;
}

const RichTextContent = (props: Props) => {
  return (
    <div>
      {props.blocks?.map((block) => {
        if (block && block.children) {
          const child = block.children[0];

          return (
            <Span
              key={block._key}
              textStyle={block.style}
              fontSize={props.fontSize}
              color={props.color}
              mobileFontSize={props.mobileFontSize}
            >
              {child?.text}
            </Span>
          );
        }

        return null;
      })}
    </div>
  );
};

export default RichTextContent;
