import React, { ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import Header from "./header";
import Footer from "./footer";
import { colors, DEFAULT_FONT_SIZE, DEFAULT_OFFSET } from "@util/constants";
interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: ${DEFAULT_FONT_SIZE}px;
    color: ${colors.darkBlue};
  }

  h1, h2, h3 {
    font-family: "Playfair Display";
    color: ${colors.darkBlue};
  }

  a {
    font-family: "Source Sans Pro";
  }
`;

const Main = styled.main<{}>`
  padding-top: ${DEFAULT_OFFSET}px;
  overflow-x: hidden;
`;

function Layout(props: Props) {
  const { children } = props;

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Source+Sans+Pro:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Header />

      <Main>{children}</Main>
      <Footer />
    </div>
  );
}

export default Layout;
