import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { FixedObject } from "gatsby-image";

import { A, P } from "@util/standard";
import { Image } from "@sub";
import { Maybe, Scalars } from "../../../../graphql-types";
import styled from "styled-components";
import { Color, colors } from "@util/constants";

interface Props {
  linktext?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  internallink?: Maybe<Scalars["Boolean"]>;
  newwindow?: Maybe<Scalars["Boolean"]>;
  asset?: Maybe<FixedObject>;
  className?: string;
  color?: Color;
  isUnderlined?: boolean;
  isBold?: boolean;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  download?: boolean;
}

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  underlined?: number;
  opacity?: number;
}>`
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  user-select: none;
  ${({ padding }) => padding && `padding: ${padding}`};
  text-decoration: ${(props) =>
    props.underlined === 1 ? `underline` : `none`};
`;

const ImageWrapper = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const StyledP = styled(P)<{
  color?: Color;
  hoverColor?: Color;
}>`
  ${({ color }) => color && `color:${colors[color]}`};
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${colors[hoverColor]};
    }`}

  margin: 0;
  &:hover {
    font-weight: 600;
  }
`;

const Link = ({
  linktext,
  url,
  internallink,
  newwindow,
  asset,
  className = "",
  color,
  hoverColor,
  isUnderlined,
  isBold,
  padding,
  opacity,
  download,
}: Props) => {
  if (internallink) {
    const to = url === "/" ? "/" : `/${url}`;
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        underlined={isUnderlined ? 1 : 0}
        opacity={opacity}
      >
        {asset ? (
          <ImageWrapper>
            <Image fixed={asset} />
          </ImageWrapper>
        ) : (
          <StyledP
            hoverColor={hoverColor}
            color={color}
            underlined={isUnderlined}
            fontWeight={isBold ? "bold" : "normal"}
          >
            {linktext}
          </StyledP>
        )}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      className={className}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
      color={color}
      opacity={opacity}
      underlined={isUnderlined}
      download={download}
    >
      {asset ? (
        <ImageWrapper>
          <Image fixed={asset} />
        </ImageWrapper>
      ) : (
        <StyledP>{linktext}</StyledP>
      )}
    </A>
  );
};

export default Link;
